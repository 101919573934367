import React,{useState} from 'react'
import logo from '../assets/logo.webp'
import { Link} from 'react-router-dom'
import '../styles/Navbar.css'
import ReorderIcon from '@mui/icons-material/Reorder';




const Navbar = () => {

    const [showLinks, setShowLinks] = useState(false)
    function Click(){
        setShowLinks(!showLinks)
    }
    return (
        <div className="navbar">
            <div className="leftSide" id={showLinks ? 'open' : 'close'}>
            <Link to='/'><img src={logo} /></Link>
                
                <div className="hiddenLinks">
                <Link to='/'>HOME</Link>
                <Link to='/menu'>MENU</Link>
                <Link to='/about'>ABOUT</Link>
                <Link to='/contact'>CONTACT</Link>
                </div>
            </div>

            <div className="rightSide">
                <Link to='/'>HOME</Link>
                <Link to='/menu'>MENU</Link>
                <Link to='/about'>ABOUT</Link>
                <Link to='/contact'>CONTACT</Link>
                <button onClick={Click}>
                    <ReorderIcon/>
                </button>
                
            </div>

        </div>
        
    )
}

export default Navbar