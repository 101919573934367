import React from 'react'
import '../styles/Contact.css'
import contact from '../assets/contact.jpg'



const Contact = () => {
    return (
        <div className="contact">
            <div className="leftSide"  style={{ backgroundImage: `url(${contact})`}}></div>
            <div className="rightSide">
                <h1>Contact Us</h1>
                <form id=" contact-form" method='POST'>

                    <label htmlFor='name'> Full Name</label>
                    <input name='name'type="text"  placeholder='Enter your Name'/>

                    <label htmlFor='email'> Email</label>
                    <input name='name'type="text"  placeholder='Enter your Email'/>

                    <label htmlFor='message'> Message</label>
                    <textarea name="message" placeholder='Enter your message'  rows="10"></textarea>

                    <button type='submit'> Send Message</button>

                </form>
            </div>
        </div>
    )
}

export default Contact