import React from 'react'
import banner from '../assets/banner.jpg'
import { Link} from 'react-router-dom'
import '../styles/Home.css'



const Home = () => {
    return (
    <div className='home' style={{backgroundImage: `url(${banner})`}}>
        <div className="headerContainer">
            <h1>Food Corner</h1>
            <p>CAMEROON FOOD AT A CLICK</p>

            <Link to='/menu'>
                <button>Order Now</button>
            </Link>
            
            
        </div>
    </div>
    )
}

export default Home