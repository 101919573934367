import React from 'react'
import '../styles/Footer.css'
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
    return (
    <div className='footer'>
        <div className="socialMedia">
            <InstagramIcon/>
            <TwitterIcon/>
            <LinkedInIcon/>
            <FacebookIcon/>
        </div>
            <p>&copy;2023 Cameroonfood.com</p>
    </div>
    )
}

export default Footer