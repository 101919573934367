import React from 'react'
import achu from '../assets/achu.jpg'
import corn_chaff from '../assets/corn_chaff.jpg'
import kondre from '../assets/kondre.jpg'
import mbongo_tchobi from '../assets/mbongo_tchobi.jpg'
import ndole from '../assets/ndole.jpg'
import poulet from '../assets/poulet.jpg'
import suya from '../assets/suya.jpg'

export const Data = [
    {
    name: 'Achu',
    image: achu,
    price: 75
    },
    {
    name: 'Corn Chaff',
    image: corn_chaff,
    price: 80
    },
    {
    name: 'Kondre',
    image: kondre,
    price: 55
    },
    {
    name: 'Mbongo Tchobi',
    image: mbongo_tchobi,
    price: 65
    },
    {
    name: 'Ndole',
    image: ndole,
    price: 40
    },
    {
    name: 'Poulet DJ',
    image: poulet,
    price: 60
    },
    {
    name: 'Suya',
    image: suya,
    price: 100
    },
]